<template>
  <div class="flatfinder-filter-items">
    <div v-for="(filter, index) in filters" class="flatfinder-filter-items__item" :key="index">
      <component
        :is="filter.component"
        :key="'filter_' + index"
        :value="data[filter.key].value"
        @input="$ev => $emit('update:filter', { key: filter.key, value: $ev })"
        v-bind="filter.bindings"
        v-on="filter.listeners"
        class="flatfinder-filter-items__item-filter"
        :class="{ 'flatfinder-filter-items__item-filter--disabled': !data[filter.key].enabled }"
      >
        <template #remove>
          <ButtonComponent
            :label="$t('remove')"
            class="flatfinder-filter-items__item-remove"
            icon-placement="left"
            :icon="['fal', 'times']"
            theme="error"
            @click="$emit('remove:filter', filter)"
          />
        </template>
      </component>
      <slot />

      <div
        v-if="!data[filter.key].enabled"
        class="flatfinder-filter-items__item-disabled"
        @click="data[filter.key].enabled = true"
      >
        <div class="flatfinder-filter-items__item-disabled-icon">
          <FontAwesomeIcon :icon="filter.bindings.icon" size="lg" />
        </div>
        <label class="flatfinder-filter-items__item-disabled-label">
          <span>{{ $t('clickToFilter') }}</span>
          <span>{{ filter.bindings.label }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss">
.flatfinder-filter-items {
  display: contents;

  &__item {
    &-filter {
      &--disabled {
        opacity: 0;
      }
    }

    &-disabled {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &-disabled,
    &-add {
      cursor: pointer;

      display: flex;
      padding: 0 1rem;
      align-items: center;
      gap: 0.5rem;

      &-icon {
        position: relative;
        width: 45px;
        aspect-ratio: 1/1;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
      }

      &-label {
        padding-right: 1.5em;
        cursor: inherit;

        span {
          display: block;

          &:first-child {
            opacity: 0.8;
            font-weight: normal;
            white-space: nowrap;
            font-size: 0.7em;
          }
        }
      }
    }

    &-remove {
      background-color: transparent !important;
      color: var(--error) !important;
      border: none !important;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.1rem 0.4rem;

      &:before {
        background-color: transparent !important;
      }

      & > span {
        padding: 0 !important;
      }
    }
  }
}
</style>
