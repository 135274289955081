<template>
  <div class="flatfinder-filter-type-boolean">
    <div class="flatfinder-filter-type-boolean__header">
      <label>
        <span>{{ label }}</span>
        <FontAwesomeIcon :icon="$attrs.icon" class="ml-1" />
      </label>
      <slot name="remove"></slot>
    </div>
    <div>
      <ChoiceComponent
        :value="value"
        @input="$ev => $emit('input', $ev)"
        :values="[
          { label: $t('yes'), value: true },
          { label: $t('no'), value: false },
        ]"
      />
    </div>
  </div>
</template>

<script>
import { ChoiceComponent } from 'vue-elder-choice'

export default {
  props: {
    value: Boolean,
    label: String,
  },
  components: {
    ChoiceComponent,
  },
}
</script>

<style lang="scss">
.flatfinder-filter-type-boolean {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-size: 0.8em;
    margin-bottom: 0.5rem;
  }

  .elder-choices {
    margin: 0;
    gap: 0.5rem;

    &__item {
      margin: inherit;

      &-label {
        padding: 0.25rem 1rem;
      }
    }
  }
}
</style>
