<template>
  <div class="flatfinder-filter-type-select">
    <div class="flatfinder-filter-type-select__header">
      <label>
        <span>{{ label }}</span>
        <FontAwesomeIcon :icon="$attrs.icon" class="ml-1" />
      </label>
      <slot name="remove"></slot>
    </div>
    <div>
      <DropdownComponent
        :close-on-click="false"
        placement="bottom-start"
        class="flatfinder-filter-type-select__dropdown"
      >
        <ButtonComponent :label="triggerLabel" class="flatfinder-filter-type-select__trigger" />
        <template #dropdown>
          <CheckboxComponent
            v-for="(item, index) in items"
            :key="index"
            :label="item.label"
            :state="item.value"
            :value="value"
            @input="onInput"
          />
        </template>
      </DropdownComponent>
    </div>
  </div>
</template>

<script>
import { DropdownComponent } from 'vue-elder-dropdown'
import { CheckboxComponent } from 'vue-elder-checkbox'
export default {
  props: {
    value: Array,
    items: {
      type: Array,
    },
    label: String,
  },
  computed: {
    triggerLabel() {
      let values = this.value.slice(0, 3)
      let others = values.length < this.value.length ? ` +${this.value.length - values.length}` : ''
      return `${values.map(v => this.items.find(i => i.value === v).label).join(', ')}${others}`
    },
  },
  methods: {
    onInput(val) {
      if (!val.length) {
        this.$emit('disable')
        val = [...this.items.map(v => v.value)]
      }
      return this.$emit('input', val)
    },
  },
  components: {
    DropdownComponent,
    CheckboxComponent,
  },
}
</script>

<style lang="scss">
.flatfinder-filter-type-select {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-size: 0.8em;
    margin-bottom: 0.25rem;
  }

  &__trigger {
    width: 100%;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    white-space: initial !important;
    border: none !important;
    margin-top: 0.5rem;

    .elder-button__label {
      padding: 0.5rem 0.7rem;
      text-align: left;
    }
  }

  &__dropdown {
    .elder-dropdown__content-box {
      padding: 1rem;
      gap: 0.5rem;
      min-width: 200px;
    }

    .elder-checkbox {
      display: block;
    }
  }
}
</style>
